<template>
<div>
  <van-image :src="poster" width="100%"></van-image>
  <van-tabbar placeholder fixed safe-area-inset-bottom v-model="active">
    <van-row type="flex" align="bottom" style="width: 100%;">
      <van-col span="24">
        <van-button type="default" hairline block color="linear-gradient(180deg, #FFEED6 13.64%, #F9D099 100%)" icon="back-top">
          长按图片保存到相册
        </van-button>
      </van-col>
    </van-row>
  </van-tabbar>
</div>
</template>

<script>
import Vue from 'vue';
import { Image as VanImage, Tabbar, TabbarItem, Row, Col } from 'vant';
import {getPoster} from '../utils/helper'

Vue.use(Row);
Vue.use(Col);
Vue.use(VanImage);
Vue.use(Tabbar);
Vue.use(TabbarItem);
export default {
  name: "Poster",
  data(){return{
    active: 0,
    poster:require("@/assets/picture.png")
  }},
  created() {
    const posterId = parseInt(this.$route.params.id)
    this.poster = getPoster(posterId)
  }
}
</script>

<style scoped lang="sass">
.van-button__content,.van-button__text
  color: #E16D42
  font-size: 17px
</style>
